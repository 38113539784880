import logo from './Logo2015.jpg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FadeIn from "react-fade-in";
import Icon from '@mdi/react';
import { mdiPhone, mdiEmail, mdiFax } from '@mdi/js';

function App() {
    return (
        <div className={"container p-lg-5 mt-2 fade-in teaser"}>
            <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 col-sm-12">
                    <div className="card shadow text-center">
                        <FadeIn>
                            <img className="logo pt-3" src={logo} alt="Card image cap"/>
                        </FadeIn>
                        <FadeIn delay={500}>

                            <div className="card-body">
                                <h5 className="card-title teaser">Neuer Internetauftritt im Aufbau</h5>
                                <p className="card-text teaser">In Kürze finden Sie hier den neuen Internetauftritt
                                    der <p>Seil- &
                                        Hebetechnik Jentzsch GmbH.</p></p>
                            </div>
                        </FadeIn>

                    </div>
                </div>
                <div className="col-lg-3"></div>
            </div>


            <div className="row mt-5">
                <div className="col-lg-3"></div>
                <div className="col-lg-6 col-sm-12">
                    <FadeIn delay={1500}>
                        <div className="card shadow text-start">
                            <div className="card-body">
                                <h5 className="card-title teaser">Impressum</h5>
                                <p className="card-text teaser">Seil- &
                                    Hebetechnik Jentzsch GmbH<br/><br/>Geschäftsführer: Daniel Strnad und Rico Sonnwald<br/>
                                    August-Bebel Straße 5b <br/>D-15234 Frankfurt(Oder) <br/><br/>
                                    <Icon path={mdiEmail} size={1}/> info@sht-jentzsch.de<br/>
                                    <Icon path={mdiPhone} size={1}/> 0335/400 11 42<br/>
                                    <Icon path={mdiFax} size={1}/> 0335/400 11 44<br/><br/>
                                    Umsatzsteuer-Identifikationsnummer: DE336178421
                                </p>
                            </div>
                        </div>
                    </FadeIn>
                </div>
                <div className="col-lg-3"></div>
            </div>
        </div>
    );
}

export default App;
